// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco } from "../../components/Botoes";
import NumerosDaSifat from "../../components/NumerosDaSifat";
import NossasSolucoes from "../../components/NossasSolucoes";
import TecnologiasOferecidas from "../../components/TecnologiasOferecidas";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import CelularMonitorTrans from "../../assets/imagens/CelularMonitorTrans.webp";
import FundoCinzaDegrade from "../../assets/imagens/FundoCinzaDegrade.webp";
import FundoAzul from "../../assets/imagens/FundoAzulComLogo.jpg";
import FundoCinza from "../../assets/imagens/FundoCinzaDegradeHorizontal.jpg";

export default function Home() {
	return (
		<PageLayout>
			<SecaoPagina $bgimagem={FundoCinzaDegrade}>
				<BlocoTituloTextoBotaoImagem
					margemImagem={null}
					botao2={null}
					corParagrafo={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoB={null}
					imagem={CelularMonitorTrans}
					possuiLogosClientes={true}
					botao1={<BotaoFaleConosco $grow={'0'}/>}
					paragrafo1={
						"Nossas soluções vão resolver seus problemas de gestão e automatizar vários processos, para que você possa ter mais qualidade de vida e tempo para si."
					}
					tituloTipoA={true}
					titulo1={"Há 30 anos oferecendo excelência em"}
					titulo2={"Sistemas de Gestão."}
				/>
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoAzul} $gap={"4em"}>
				<NumerosDaSifat />
				<NossasSolucoes />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoCinza}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
			>
				<TecnologiasOferecidas />
			</SecaoPagina>
		</PageLayout>
	);
}
