import { TextosWaychef } from "./TextosWaychef.js";
import { TextosEfex } from "./TextosEfex.js";
import { TextosFlutt } from "./TextosFlutt.js";
import { TextosLumien } from "./TextosLumien.js";
import { TextosWaymenu } from "./TextosWaymenu.js";
import {
	BotaoFaleConosco,
	BotaoReceberLigacao,
	BotaoTesteGratisWaychef,
	BotaoInscrevaWaymenu
} from "../Botoes/index.js";
// Bootstrap
import Accordion from "react-bootstrap/Accordion";
import { useContext } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
// Outras bibliotecas
import styled from "styled-components";

// Estilo da caixa com pergunta
const ACTIVE = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	backgroundColor: "#efefef",
	borderRadius: "var(--border-radius-s)",
	border: "none",
	padding: "1em",
	textAlign: "center",
	fontWeight: "var(--regular-weight)",
	fontSize: "var(--small-font)",
	justifyContent: "center",
};
const INACTIVE = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center",
	borderRadius: "var(--border-radius-s)",
	padding: "1em",
	textAlign: "center",
	filter: "drop-shadow(1px 1px 4px black)",
	fontWeight: "var(--regular-weight)",
	fontSize: "var(--small-font)",
	justifyContent: "center",
	background: "transparent",
	border: "2px solid white",
	color: "white",
};

// Componente personalizado do Bootstrap - Pergunta selecionada/des-selecionada
function Pergunta({ children, eventKey, callback }) {
	const { activeEventKey } = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = activeEventKey === eventKey;

	return (
		<button
			type="button"
			style={isCurrentEventKey ? ACTIVE : INACTIVE}
			onClick={decoratedOnClick}
		>
			{children}
		</button>
	);
}

// Componentes
const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5em;
	width: 100%;
	color: #4e4d4d;
`;

const Titulo = styled.h1`
	color: white;
	text-align: left;
	font-weight: var(--regular-weight);
	font-size: var(--bigger-font);
`;

const BotoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2em;
	width: 100%;
	justify-content: center;
`;

const MobileContainer = styled(Accordion)`
	display: flex;
	flex-direction: column;
	gap: 1em;
	width: 100%;

	@media screen and (min-width: 993px) {
		display: none;
	}
`;

const Item = styled(Accordion.Item)`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;

const RespostaContainer = styled(Accordion.Body)`
	background-color: #efefef;
	border-radius: var(--border-radius-s);
	padding: 0.5em 0.5em;

	@media screen and (min-width: 350px) {
		padding: 2em;
	}
`;

const Paragrafo = styled.p`
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
`;

const ListaContainer = styled.ul`
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	text-align: left;
`;

const ItemLista = styled.li`
	font-weight: var(--weak-weight);
	font-size: var(--small-font);

	&::marker {
		color: #f26a35;
		font-weight: var(--strong-weight);
	}
`;

const DesktopContainer = styled(Accordion)`
	display: none;

	@media screen and (min-width: 993px) {
		display: flex;
		flex-direction: row;
		gap: 1em;
		width: 100%;
	}
`;

const DesktopWrapper = styled.div`
	width: ${(props) => props.$width || ""};
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: ${(props) => props.$gap || "0.9em"};
`;

let Textos;

// Funções de geração dinâmica de componentes
function GerarPerguntas() {
	let perguntas = [];

	Object.keys(Textos).map((key) => {
		const conteudo = Textos[key];

		perguntas.push(
			<Pergunta eventKey={key}>{conteudo.titulo}</Pergunta>
		);
	});

	return perguntas;
}

function GerarRespostas() {
	let respostas = [];

	Object.keys(Textos).map((key) => {
		const conteudo = Textos[key];
		const corpo = conteudo.corpo;

		respostas.push(
			<RespostaContainer key={key}>
				{Object.keys(corpo).map((subkey, index) => {
					if (corpo[subkey].tipo === "p") {
						return <Paragrafo key={index}>{corpo[subkey].texto}</Paragrafo>;
					} else if (corpo[subkey].tipo === "li") {
						return (
							<ListaContainer key={index}>
								{corpo[subkey].texto.map((item, i) => (
									<ItemLista key={i}>{item}</ItemLista>
								))}
							</ListaContainer>
						);
					}
				})}
			</RespostaContainer>
		);
	});

	return respostas;
}

function GerarConteudoMobile() {
	let conteudoMobile = [];
	const perguntas = GerarPerguntas();
	const respostas = GerarRespostas();

	Object.keys(Textos).map((key) => {
		conteudoMobile.push(
			<Item key={key} eventKey={key}>
				{perguntas[key]}
				{respostas[key]}
			</Item>
		);
	});

	return conteudoMobile;
}

function GerarConteudoDesktop() {
	let conteudoDesktop = [];
	const perguntas = GerarPerguntas();
	const respostas = GerarRespostas();

	conteudoDesktop.push(
		<DesktopWrapper $width={"30%"}>
			{Object.keys(Textos).map((key) => (
				<Item key={key}>{perguntas[key]}</Item>
			))}
		</DesktopWrapper>
	);

	conteudoDesktop.push(
		<DesktopWrapper $width={"70%"} $gap={"0rem"}>
			{Object.keys(Textos).map((key) => (
				<Item key={key} eventKey={key}>{respostas[key]}</Item>
			))}
		</DesktopWrapper>
	);

	return conteudoDesktop;
}

function Duvidas({ titulo, botao }) {
	return (
		<Container>
			<Titulo>{titulo}</Titulo>

			<MobileContainer defaultActiveKey="0">
				{GerarConteudoMobile()}
			</MobileContainer>

			<DesktopContainer defaultActiveKey="0">
				{GerarConteudoDesktop()}
			</DesktopContainer>

			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
				{botao ? botao : null}
			</BotoesContainer>
		</Container>
	);
}

// Componentes
export function DuvidasWaychef() {
	Textos = TextosWaychef;
	return <Duvidas titulo={"Dúvidas frequentes sobre o sistema de gestão Waychef"} botao={<BotaoTesteGratisWaychef/>}/>;
}

export function DuvidasEfex() {
	Textos = TextosEfex;
	return <Duvidas titulo={"Dúvidas frequentes sobre o ponto de venda Efex"}/>;
}

export function DuvidasFlutt() {
	Textos = TextosFlutt;
	return <Duvidas titulo={"Dúvidas frequentes sobre o ponto de venda Flutt"}/>;
}

export function DuvidasLumien() {
	Textos = TextosLumien;
	return <Duvidas titulo={"Dúvidas frequentes sobre o Lumien"} />;
}

export function DuvidasWaymenu() {
	Textos = TextosWaymenu;
	return <Duvidas titulo={"Dúvidas frequentes sobre o cardápio digital Waymenu"} botao={<BotaoInscrevaWaymenu />}/>;
}
