import styled from "styled-components";
import { Link } from "react-router-dom";
// Componentes personalizados
import { BotaoFaleConosco, BotaoAreaCliente } from "../Botoes";
// Imagens
import LogoSifatAzulHorizontal from "../../assets/imagens/Logomarca Sifat 2019 - Azul Horizontal.png";
// Bootstrap
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from 'react-bootstrap/NavDropdown';

const StyledLogoSifatAzulHorizontal = styled.img`
	width: 5em;
	height: auto;
`;

const LinkStyled = styled(Link)`
	text-decoration: none;
	color: #1b355e;
	font-weight: var(--regular-weight);

	&:hover {
		color: #1b355e;
	}

	&:link {
		color: #1b355e;
	}

	&:visited {
		color: #1b355e;
	}
`;

const NavStyled = styled(Nav)`
	display: flex;
	flex-direction: column;
	gap: 2em;

	@media screen and (min-width: 993px) {
		align-items: center;
	}
`;

const ContainerStyled = styled(Container)`
	max-width: var(--max-section-w);
`;

function LinksDropdown() {
	return(
		<NavDropdown
			id="nav-dropdown"
			title="Soluções"
		>
			<NavDropdown.Item>
					<LinkStyled to={`/waychef`}>
						Waychef - ERP + PDV Food Service
					</LinkStyled>
			</NavDropdown.Item>

			<NavDropdown.Item>
				<LinkStyled to={`/waymenu`}>
					Waymenu - Delivery e Cardápio Digital
				</LinkStyled>
			</NavDropdown.Item>

			<NavDropdown.Item>
				<LinkStyled to={`/efex`}>
					Efex - PDV Mercados e casa de carnes
				</LinkStyled>
			</NavDropdown.Item>

			<NavDropdown.Item>
				<LinkStyled to={`/lumien`}>Lumien - Posto de combustível</LinkStyled>
			</NavDropdown.Item>
			
			<NavDropdown.Item>
				<LinkStyled to={`/flutt`}>Flutt - PDV Varejo</LinkStyled>
			</NavDropdown.Item>
		</NavDropdown>
	);
}

export default function Header() {
	return (
		<Navbar key={"lg"} expand={"lg"} className="bg-body-tertiary" sticky="top">
			<ContainerStyled fluid>
				<Navbar.Brand as={Link} to="/">
					<StyledLogoSifatAzulHorizontal
						src={LogoSifatAzulHorizontal}
						alt="Logo Sifat"
					/>
				</Navbar.Brand>

				<Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"md"}`} />

				<Navbar.Offcanvas
					id={`offcanvasNavbar-expand-${"md"}`}
					aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
					placement="end"
				>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${"md"}`}>
							SIFAT
						</Offcanvas.Title>
					</Offcanvas.Header>

					<Offcanvas.Body className={"justify-content-end"}>
						<NavStyled>
							<LinkStyled to={`/`}>Home</LinkStyled>
							<LinkStyled to={`/sobre-sifat`}>Sobre nós</LinkStyled>
							<LinksDropdown />
							<LinkStyled to={`/seja-parceiro`}>Seja um parceiro</LinkStyled>

							{<BotaoFaleConosco />}
							{<BotaoAreaCliente />}
						</NavStyled>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</ContainerStyled>
		</Navbar>
	);
}
