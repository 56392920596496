export const TextosLumien = {
	0: {
		titulo: "Está disponível suporte técnico ao adquirir o sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Com certeza! Oferecemos suporte técnico completo ao adquirir o sistema. Contamos com uma central de ligações e WhatsApp, onde nossos consultores altamente qualificados estão prontos para te auxiliar em qualquer momento. Atente-se às modalidades de suporte disponíveis de acordo com o plano contratado."
			},
			1: {
				tipo: "p",
				texto:
					"Nosso atendimento durante o horário comercial é de segunda a sexta-feira das 07h às 19h e aos sábados das 07h às 13h. Além disso, oferecemos plantão de atendimento de segunda a sexta-feira das 19h às 00h, aos sábados das 13h às 00h e aos domingos das 07h às 18h. Para maior comodidade, também disponibilizamos atendimento via WhatsApp de segunda a sexta-feira das 08h às 17h30."
			}
		}
	},
	1: {
		titulo: "Como funciona o treinamento e implantação do sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"O treinamento e a implantação do Lumien são realizados presencialmente. Um técnico especializado acompanha sua equipe no local por até 4 dias, garantindo a fluidez e o bom funcionamento do sistema. Esse suporte inicial assegura que todos os funcionários estejam bem preparados para utilizar todas as funcionalidades do Lumien, proporcionando uma transição suave e eficiente."
			}
		}
	},
	2: {
		titulo: "Com o Lumien Pay, vou ter controle sobre quem está recebendo os pagamentos?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, com o Lumien Pay você terá controle total sobre os pagamentos recebidos. O sistema permite a identificação e o controle de recebimentos por frentista, além de oferecer uma análise detalhada das movimentações de combustível por turno. Isso ajuda o proprietário a identificar e resolver possíveis gargalos de atendimento, otimizando o processo de vendas e garantindo maior eficiência operacional."
			}
		}
	},
	3: {
		titulo: "Ficarei sem sistema caso a internet falhe?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Não, você não ficará sem sistema caso a internet falhe. O Lumien opera com um banco de dados offline, garantindo que todas as operações continuem funcionando normalmente, mesmo sem conexão com a internet. Isso proporciona maior segurança e confiabilidade para a gestão do seu posto de combustível."
			}
		}
	},
	4: {
		titulo:
			"Vou conseguir ter controle sobre a finalizadora?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o Lumien oferece controle abrangente sobre sua finalizadora. Você poderá gerenciar diversos dados importantes, como o controle de cartões e outras formas de pagamento. Essa funcionalidade permite uma administração mais precisa e eficiente das operações financeiras do seu posto, garantindo transparência e segurança nas transações."
			}
		}
	}
};
