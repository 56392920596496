import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoFlutt } from "../../components/PorqueProduto";
import { FuncoesFlutt } from "../../components/Funcoes";
import { TituloFeaturesTextoFlutt } from "../../components/TituloFeaturesTexto";
import { DuvidasFlutt } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoHeader from "../../assets/imagens/flutt/header.webp";
import FundoFuncoes from "../../assets/imagens/flutt/fundo-funcoes.webp";
import FundoDuvidas from "../../assets/imagens/flutt/fundo-perguntas.webp";
import TelasAplicacao from "../../assets/imagens/flutt/Homepage.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Flutt - Branco.svg";

const Logo = styled.img`
	width: 4em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageFlutt() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={FundoHeader}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Flutt"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Com o Flutt, o seu negócio varejista se destaca! Oferecemos um sistema completo, com ferramentas diferenciadas e específicas para impulsionar o seu sucesso."
					}
					tituloTipoB={true}
					titulo1={
						"PDV + ERP completo para lojas de varejo"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoFlutt />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoFlutt />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoFuncoes} $paddingmin={"2em"}>
				<FuncoesFlutt />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoDuvidas} $paddingmin={"2em"}>
				<DuvidasFlutt />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#000087"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
